import React from 'react'
import Layout from '../components/Layout'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import ogImage from '../resources/images/logos/featured_image.jpg'

class productSheet extends React.Component {
    render() {
        const currentProductSheet: any = get(this.props, 'data.contentfulProductSheet')

        return (
            <Layout>
                <Helmet>
                    <title>{currentProductSheet?.title} | Lenses.io</title>
                    <meta name="twitter:text:title" content={currentProductSheet?.title} />
                    <meta name="description" content={currentProductSheet?.description} />
                    <meta property="og:description" content={currentProductSheet?.description} />
                    <meta
                        property="twitter:description"
                        content={currentProductSheet?.description}
                    />

                    <meta name="keywords" content={currentProductSheet?.keywords} />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@lensesio" />
                    <meta property="og:title" content={currentProductSheet?.title} />
                    <meta property="twitter:title" content={currentProductSheet?.title} />
                    <meta property="og:image" content={ogImage} />
                    <meta property="twitter:image" content={ogImage} />
                </Helmet>
                <StaticImage
                    className="home-fixed-top"
                    src="../resources/images/homepage/hero_lens.svg"
                    placeholder="blurred"
                    alt="Hero Lenses"
                />
                <section className="pt-5 mt-5 pb-5">
                    <div className="container-1 ipad-margin-top">
                        <div className="d-flex flex-wrap mt-3">
                            <div className="col-md-6 pt-0">
                                <p className="title breadcrumb-text pt-4">
                                    <a href="/resources/" className="title_text">
                                        RESOURCES
                                    </a>{' '}
                                    /
                                    <a
                                        href="/product-sheets/"
                                        className="title_text font-weight-bold"
                                    >
                                        PRODUCT SHEETS
                                    </a>
                                </p>
                                <h1>{currentProductSheet?.title}</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="summary" className="pt-5 pb-5 mobile-margin-bottom">
                    <div className="container-1">
                        <div className="d-flex flex-wrap">
                            <div className="col-md-8">
                                <object
                                    className="w-100 height-800"
                                    data={`/${currentProductSheet.pdf.file.url}`}
                                    type="application/pdf"
                                >
                                    <div className="ds-card">
                                        <span className="ds-card-label-gray">
                                            {' '}
                                            {currentProductSheet.type}
                                        </span>
                                        <h5 className="ds-card-title">
                                            {currentProductSheet.title}
                                        </h5>
                                        <div className="text-center">
                                            <a
                                                href={currentProductSheet.pdf.file.url}
                                                className="btn btn-prim"
                                            >
                                                DOWNLOAD
                                            </a>
                                        </div>
                                    </div>
                                </object>
                            </div>
                            <div className="col-md-4 d-none d-md-block">
                                <div className="roll-button">
                                    <a href="/start/" className="bg-red text-white">
                                        <span data-hover="Download">Download</span>
                                    </a>
                                </div>
                                <div className="p-3 mt-3 rounded border shadow-sm product-sheet-side-container">
                                    <span className="f-12">DOWNLOADS</span> <br />
                                    <p>
                                        <a
                                            className="link-text"
                                            href={currentProductSheet.pdf.file.url}
                                        >
                                            Download this PDF
                                        </a>
                                    </p>
                                    <span className="iq-mt-5 f-12">CONTACT US</span> <br />
                                    <p>
                                        <a
                                            className="link-text"
                                            href={`/contact-us/?s=product-sheet-${currentProductSheet.pdf.title}`}
                                        >
                                            Contact our Team to learn more
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}

export default productSheet

export const productSheetQuery = graphql`
    query ProductSheetBySlug($slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        contentfulProductSheet(slug: { eq: $slug }) {
            description
            slug
            title
            keywords
            pdf {
                title
                file {
                    url
                }
            }
        }
    }
`
